<template>
    <div>
       <AppSpinner v-if="loadingSpinner" :loading="loadingSpinner"/>

        <transition mode="out-in" name="fade">
            <div class="installation-table" v-if="!loadingSpinner">
                <div class="sticky_header">
                    <div class="singleInstallationRow__btns md-layout md-raised">
                        <div
                            class="md-layout-item md-xlarge-size-5 md-large-size-5 md-medium-size-10 md-small-size-20 singleInstallationRow__check-btn check-btn main-checkbox">
                            <input type="checkbox" class="input-check-box" v-model="isAllChecked">
                            <md-icon>arrow_drop_down</md-icon>
                        </div>
                        <div class="md-layout-item md-xlarge-size-30 md-large-size-40 md-medium-size-70 md-small-size-70">
                            <md-button @click="updateMarkedAsInstalled()">Mark installed <md-icon class="main-icons icons">layers</md-icon></md-button>
                            <md-button @click="updateMarkedAsRemoved()">Mark removed <md-icon class="main-icons icons">layers_clear</md-icon></md-button>
                        </div>
                    </div>

                    <div class="singleInstallationRow__header md-layout md-raised">
                        <h2 class="md-layout-item md-xlarge-size-10 md-large-size-10 md-medium-size-10 md-small-size-10"></h2>
                        <h2 class="md-layout-item md-xlarge-size-10 md-large-size-10 md-medium-size-10 md-small-size-15"
                            @click="orderResults('id')">
                            <md-tooltip md-direction="top">Click to sort</md-tooltip>
                            ART ID</h2>
                        <h2 class="md-layout-item md-xlarge-size-15 md-large-size-15 md-medium-size-15 md-small-size-25"
                            @click="orderResults('spaceDescription')">
                            <md-tooltip md-direction="top">Click to sort</md-tooltip>
                            AD CODE</h2>
                        <h2 class="md-layout-item md-xlarge-size-15 md-large-size-15 md-medium-size-15 md-small-size-25"
                            @click="orderResults('materialName')">
                            <md-tooltip md-direction="top">Click to sort</md-tooltip>
                            MATERIAL</h2>
                        <h2 class="md-layout-item md-xlarge-size-15 md-large-size-15 md-medium-size-15 md-small-size-25"
                            @click="orderResults('statusName')">
                            <md-tooltip md-direction="top">Click to sort</md-tooltip>
                            STATUS</h2>
                        <h2 class="md-layout-item md-xlarge-size-10 md-large-size-10 md-medium-size-15 mobile-remove"
                            @click="orderResults('size')">
                            <md-tooltip md-direction="top">Click to sort</md-tooltip>
                            SIZE</h2>
                        <h2 class="md-layout-item md-xlarge-size-20 md-large-size-20 md-medium-size-20 mobile-remove"
                            @click="orderResults('deposition')">
                            <md-tooltip md-direction="top">Click to sort</md-tooltip>
                            DISPOSITION</h2>
                        <h2 class="md-layout-item md-xlarge-size-5 md-large-size-5 md-medium-size-5 mobile-remove"></h2>
                    </div>
                </div>

                <div v-for="(installation, index) in sortedInstallations" :key="index + '_singleClass'">

                    <div class="singleInstallationRow md-raised md-layout"
                         :class="{'expanded': installation.is_expanded}">

                        <div
                            class="md-layout-item md-xlarge-size-10 md-large-size-10 md-medium-size-10 md-small-size-10">
                            <div class="md-layout md-layout-item">
                                <div class="singleInstallationRow__check-btn check-btn">
                                    <label>
                                        <input type="checkbox" class="input-check-box"
                                               v-model="installation.is_checked">
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div
                            class="md-layout-item md-xlarge-size-90 md-large-size-90 md-medium-size-90 md-small-size-90"
                            @click.stop="openModalDetails(installation)">
                            <div class="md-raised md-layout">
                                <div
                                    class="singleInstallationRow__data blue-color special-labels md-xlarge-size-10 md-layout-item md-large-size-10 md-medium-size-10 md-small-size-15">
                                    {{ installation.id }}
                                </div>

                                <div
                                    class="singleInstallationRow__data blue-color md-layout-item md-xlarge-size-20 md-large-size-20 md-medium-size-20 md-small-size-30">
                                    {{ installation.spaceDescription }}
                                </div>

                                <div
                                    class="singleInstallationRow__data md-layout-item md-xlarge-size-10 md-large-size-10 md-medium-size-10 md-small-size-30">
                                    <!--{{installation.material_name}}-->
                                    {{ installation.materialName }}
                                </div>

                                <div
                                    class="singleInstallationRow__data status_name md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-25">
                                    {{ installation.statusName }}
                                </div>

                                <div
                                    class="singleInstallationRow__data special-labels md-layout-item md-xlarge-size-5 md-large-size-5 md-medium-size-10 mobile-remove">
                                    {{ installation.size }}
                                </div>

                                <div
                                    class="singleInstallationRow__data special-labels md-layout-item md-xlarge-size-25 md-large-size-20 md-medium-size-20 mobile-remove">
                                    {{ installation.deposition }}
                                </div>

                                <div class="md-layout-item md-large-size-5 md-medium-size-5 mobile-remove">
                                    <div class="md-layout md-layout-item">
                                        <div class="singleInstallationRow__expand-btn expand-btn"
                                             @click.stop="goToSeeDetails(installation)">
                                            <md-icon>expand_more</md-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  <div v-if="installation.is_expanded">
                  <MaterialPDF :pdf="pdf" />
                  </div>
<!--                    <installation-detail-card -->
<!--                                              :installation="installation"-->
<!--                    ></installation-detail-card>-->
                </div>
            </div>
        </transition>

        <md-dialog :md-active.sync=showInstallationModal>
            <div class="md-layout md-alignment-bottom-space-between">
                <div @click="closeModal">
                    <md-icon class="md-size-2x">keyboard_backspace</md-icon>
                </div>
                <div @click="closeModal">
                    <md-icon class="md-size-2x">close</md-icon>
                </div>
            </div>
<!--            <installation-detail-card-->
<!--                :installation="selectedInstallationForModal"/>-->
          <MaterialPDF :pdf="pdf" />
            <md-button class="md-primary md-raised close-modal-button" @click.stop="closeModal">
                close
            </md-button>
        </md-dialog>

        <md-snackbar
            md-position="center"
            :md-active.sync="snackbar.show"
            :md-duration="snackbar.duration"
            md-persistent>

            <span>{{ snackbar.message }}</span>
            <md-button class="md-primary" @click="undoStatusUpdate">Undo</md-button>

        </md-snackbar>
    </div>
</template>

<script>
//import InstallationDetailCard from './InstallationDetailCard.vue';
import MaterialPDF from "@/components/MaterialDashboard/MaterialPDF.vue";

export default {
    name: 'InstallationDashboardTable',
    props: {
        installationsProp: {
            type: Array,
        },
    },

    data: function() {
        return {
            isAllChecked: false,
            loadingSpinner: false,
            selectedInstallationForModal: null,
            showInstallationModal: false,
            snackbar: {
                show: false,
                message: 'Last chance to undo the changes?',
                duration: 4000,
            },
            undo: false,
            currentSort:'id',
            currentSortDir:'asc',
            installations: this.installationsProp,
            pdf: "",
            currentlyExpandedInstallation: null,
        };
    },

    components: {
      MaterialPDF,
        //InstallationDetailCard,
    },

    mounted() {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
          "src",
          "//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.min.js"
      );
      // recaptchaScript.setAttribute("src", "//mozilla.github.io/pdf.js/build/pdf.js");
      document.head.appendChild(recaptchaScript);

    },

    methods: {
        showSnackBar() {
            this.undo = false;
            this.snackbar.show = true;
        },
        undoStatusUpdate(){
            this.undo = true;
            this.snackbar.show = false;
        },
        updateMarkedAsInstalled() {

            this.showSnackBar();

            setTimeout(() => {

                if (this.undo) return;

                for (let i = 0; i < this.installations.length; i++) {

                    if (this.installations[i].is_checked) {

                        this.installations[i].markInstalled();
                    }
                }

            }, 4000);
        },

        updateMarkedAsRemoved() {

            this.showSnackBar();

            setTimeout(() => {

                if (this.undo) return;

                for (let i = 0; i < this.installations.length; i++) {

                    if (this.installations[i].is_checked) {

                        this.installations[i].markRemoved();
                    }
                }

            }, 4000);
        },

        goToSeeDetails(installation) {

          if (this.currentlyExpandedInstallation && this.currentlyExpandedInstallation !== installation) {
            this.currentlyExpandedInstallation.is_expanded = false;
          }

          installation.is_expanded = !installation.is_expanded;

          this.currentlyExpandedInstallation = installation;

          let mediaType = '';

          if (installation.mediaType) {
            mediaType = 'sponsorships';
          } else {
            mediaType = 'signage-orders';
          }

          if( installation.is_expanded) {
            this.$axios.get(`/${mediaType}/${installation.id}/print-ticket`).then(res => {
              //this.pdfDialog = true;
              this.pdf = res.data;
            }).catch(err => {
              this.$emit('printFailed', err.message);
            });
          }

            this.$forceUpdate();
        },

        openModalDetails(installation) {
            if (window.screen.availWidth < 900) {
                this.selectedInstallationForModal = installation;

              let mediaType = '';

              if (installation.mediaType) {
                mediaType = 'sponsorships';
              } else {
                mediaType = 'signage-orders';
              }

              this.$axios.get(`/${mediaType}/${installation.id}/print-ticket`).then(res => {
                //this.pdfDialog = true;
                this.pdf = res.data;
              }).catch(err => {
                this.$emit('printFailed', err.message);
              });


                this.showInstallationModal = true;
                document.body.style.overflow = 'hidden';

            } else {
                this.goToSeeDetails(installation);
            }
        },

        orderResults(sortColumn) {

            //if sortColumn == current sort, reverse
            if(sortColumn === this.currentSort) {

                this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            }

            this.currentSort = sortColumn;
        },

        closeModal() {

            this.showInstallationModal = false;
            document.body.style.overflow = '';
        },
    },

    watch: {
        isAllChecked(value) {

            for (let i = 0; i < this.installations.length; i++) {

                this.installations[i].is_checked = value;
            }

            this.$forceUpdate();
        },

        installationsProp(value){
            this.installations = value;
        }
    },

    computed: {

      showScrollTopButton() {
        return window.scrollY > 10; // Adjust the value based on when you want the button to appear
      },
        sortedInstallations() {

            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.installations.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0;
            });
        }
    }
};
</script>

<style lang="scss" scoped>

.singleInstallationRow__header {
    text-align: center;
    background-color: white;
    font-weight: bold;
    color: #222222;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid gray;

    @include respond(small) {
        font-size: 0.8rem;
    }

    h2 {
        cursor: pointer;
    }
}

.singleInstallationRow {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 15px;
    border-bottom: 2px solid #7D7D7D;

    @include respond(small) {
        cursor: pointer;
    }
}

.blue-color {
    color: #313B8E;
    font-weight: 500;
}

.singleInstallationRow__data {
    font-size: 1.4rem;
}

.singleInstallationRow:hover {
    cursor: pointer;
}

.installation-table {
    border: 1px solid #B90B0B;
}

.sticky_header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9;
  box-shadow: 0 -6px 10px 5px rgba(0,0,0,0.5);
}

.check-btn {
    padding-left: 4rem;
    padding-top: 0.8rem;

    @include respond(small) {
        padding-left: 1.3rem;
    }

    .input-check-box {
        transform: scale(2.1);

        @include respond(small) {
            transform: scale(1.6);
        }
    }
}


.mobile-remove {

    @include respond(small) {
        /*display: none;*/
    }
}

.singleInstallationRow__btns {
    border-top: 1px solid gray;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 2px solid gray;

    .md-icon {
        //padding-left: 20px;
        //font-size: 45px !important;
        //
        //@include respond(small) {
        //    font-size: 25px !important;
        //    padding-left: 10px;
        //}
    }
}

.main-icons {
    margin-left: 35px;

    @include respond(small) {
        margin-left: 15px;
    }
}

.expand-btn {
    cursor: pointer;
}

.expanded {
    background-color: #f1f1f1;
}

.icons {
    cursor: pointer;
    margin-left: 45px;
    font-size: 45px !important;

    @include respond(small) {
        font-size: 45px !important;
        margin-left: 45px;
    }
}

.installation-table {
    margin-top: 40px;
    height: 98vh;
    overflow: scroll;
}

.status_name {
    @include respond(small) {
        text-align: center;
    }
}

.close-modal-button {
    margin-bottom: 20px;
}
</style>