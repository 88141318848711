<template>
    <div>
      <md-dialog :md-active.sync="pdfDialog">
        <md-dialog-title>PDF Preview</md-dialog-title>
        <md-content style="overflow-y: scroll !important;" class="md-scrollbar">
          <MaterialPDF :pdf="pdf" />
        </md-content>
        <md-dialog-actions>
          <md-button class="md-primary" @click="pdfDialog = false">Close</md-button>
        </md-dialog-actions>
      </md-dialog>
        <AppSpinner v-if="loadingSpinner" :loading="loadingSpinner"/>

        <transition mode="out-in" name="fade">

            <div class="material-table" v-if="!loadingSpinner">
                <div class="sticky_header">
                    <div class="singleMaterialRow__btns md-layout md-raised">
                        <div
                            class="md-layout-item md-xlarge-size-5 md-large-size-5 md-medium-size-10 md-small-size-20 singleInstallationRow__check-btn check-btn main-checkbox">
                            <input type="checkbox" class="input-check-box" v-model="isAllChecked">
                            <!--<md-icon>arrow_drop_down</md-icon>-->
                        </div>
                        <div class="md-layout-item md-xlarge-size-20 md-large-size-20 md-medium-size-45 md-small-size-90">
                            <AppInputSelectDropdown
                                :placeholder="'Select material'"
                                class="materials-select__field"
                                v-model="currentMaterialID">

                                <option v-for="(material, index) in materialsForDropdown" :key="index" :value="material.id">
                                    {{ material.name }}
                                </option>

                            </AppInputSelectDropdown>
                        </div>

                        <div class="md-layout-item md-xlarge-size-20 md-large-size-20 md-medium-size-45 md-small-size-90">
                            <md-button class="md-primary md-raised send-to-queue-btn"
                                       @click="sendToQueueMaterialsDropdown()">Set materials
                            </md-button>
                        </div>

                        <div class="md-layout-item md-xlarge-size-20 md-large-size-20 md-medium-size-45 md-small-size-90">
                            <AppInputSelectDropdown v-if="orderType === 'signage'"
                                                    :placeholder="'Select status'"
                                                    class="materials-select__field"
                                                    v-model="currentStatusID">
                                <option v-for="(status, index) in signageOrderStatuses" :key="index" :value="status.id">
                                    {{ status.name }}
                                </option>

                            </AppInputSelectDropdown>
                            <AppInputSelectDropdown v-if="orderType === 'sponsorship'"
                                                    :placeholder="'Select status'"
                                                    class="materials-select__field"
                                                    v-model="currentStatusID">
                                <option v-for="(status, index) in sponsorshipStatuses" :key="index"
                                        :value="status.sponsorship_status_id">
                                    {{ status.sponsorship_status_name }}
                                </option>

                            </AppInputSelectDropdown>
                        </div>


                        <div class="md-layout-item md-xlarge-size-20 md-large-size-20 md-medium-size-45 md-small-size-90">
                            <md-button class="md-primary md-raised send-to-queue-btn"
                                       @click="changeStatuses()">Set status
                            </md-button>
                        </div>
                    </div>

                    <div class="singleMaterialRow__header md-layout md-raised">
                        <h2 class="md-layout-item md-xlarge-size-5 md-large-size-5 md-medium-size-5 md-small-size-5"></h2>
                        <h2 class="md-layout-item md-xlarge-size-10 md-large-size-10 md-medium-size-10 md-small-size-15 material-hdrs"
                            @click="alphabeticalOrder('id')">
                            <md-tooltip md-direction="top">Click to sort</md-tooltip>
                            ArtID
                        </h2>
                        <h2 class="md-layout-item md-xlarge-size-10 md-large-size-10 md-medium-size-10 md-small-size-15 material-hdrs">
                            Advertiser Name
                        </h2>
                        <h2 class="md-layout-item md-xlarge-size-20 md-large-size-20 md-medium-size-20 md-small-size-25 material-hdrs"
                            @click="alphabeticalOrder('description')">
                            <md-tooltip md-direction="top">Click to sort</md-tooltip>
                            AdCode
                        </h2>
                        <h2 class="md-layout-item md-xlarge-size-15 md-large-size-15 md-medium-size-15 md-small-size-15 material-hdrs"
                            @click="alphabeticalOrder('materialName')">
                            <md-tooltip md-direction="top">Click to sort</md-tooltip>
                            Material
                        </h2>
                        <h2 class="md-layout-item md-xlarge-size-15 md-large-size-15 md-medium-size-15 md-small-size-15 material-hdrs"
                            @click="alphabeticalOrder('statusName')">
                            <md-tooltip md-direction="top">Click to sort</md-tooltip>
                            Status
                        </h2>
                        <h2 class="md-layout-item md-xlarge-size-10 md-large-size-10 md-medium-size-10 material-hdrs"
                            @click="alphabeticalOrder('size')">
                            <md-tooltip md-direction="top">Click to sort</md-tooltip>
                            Size
                        </h2>
                        <h2 class="md-layout-item md-xlarge-size-10 md-large-size-10 md-medium-size-10 material-hdrs"
                          @click="alphabeticalOrder('quantity')">
                            <md-tooltip md-direction="top">Click to sort</md-tooltip>
                            Quantity
                        </h2>
                    </div>
                </div>

                <div v-for="(material, index) in materials" :key="index + '_singleClass'">

                    <div class="singleMaterialRow md-raised md-layout">

                        <div
                            class="md-layout-item md-xlarge-size-5 md-large-size-5 md-medium-size-5 md-small-size-5">
                            <div class="md-layout md-layout-item">
                                <div class="singleMaterialRow__check-btn check-btn">
                                    <label>
                                        <input type="checkbox" class="input-check-box" v-model="material.is_checked">
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div
                            class="singleMaterialRow__data special-labels md-x large-size-10 md-layout-item md-large-size-10 md-medium-size-10 md-small-size-15">
                            <a style="color: #414a97; font-weight: bold" class="blue-color" :href="material.artworkThumbnail" target="_blank">{{ material.id }}</a>
                        </div>
                        <div
                            class="singleMaterialRow__data blue-color special-labels md-xlarge-size-10 md-layout-item md-large-size-10 md-medium-size-10 md-small-size-15">
                            {{ orderType === 'sponsorship' ? material.companyName : 'IMC' }}
                        </div>

                        <div
                            class="singleMaterialRow__data blue-color md-layout-item md-xlarge-size-20 md-large-size-20 md-medium-size-20 md-small-size-30">
                            {{ material.spaceDescription }}
                        </div>

                        <div
                            class="singleMaterialRow__data md-layout-item md-xlarge-size-15 md-large-size-15 md-medium-size-15 md-small-size-15">
                            <!--{{installation.material_name}}-->

                            <AppInputSelectDropdown
                                :placeholder="'Select material'"
                                class="materials-select__field"
                                v-model="material.materialId"
                                @change="onChangeSingleMaterialDropdown(material)">

                                <option v-for="(material, index) in materialsForDropdown" :key="index"
                                        :value="material.id">
                                    {{ material.name }}
                                </option>

                            </AppInputSelectDropdown>

                        </div>

                        <div
                            class="singleMaterialRow__data status_name md-layout-item md-xlarge-size-15 md-large-size-15 md-medium-size-15 md-small-size-15">

                            <AppInputSelectDropdown v-show="orderType === 'signage'"
                                                    :placeholder="'Select status'"
                                                    class="materials-select__field"
                                                    v-model="material.status"
                                                    @change="onChangeSingleStatusDropdown(material)">

                                <option v-for="(status, index) in signageOrderStatuses" :key="index + 'signageOrderStatuses'"
                                        :value="status.id">
                                    {{ status.name }}
                                </option>

                            </AppInputSelectDropdown>

                            <AppInputSelectDropdown v-show="orderType === 'sponsorship'"
                                                    :placeholder="'Select status'"
                                                    class="materials-select__field"
                                                    v-model="material.status"
                                                    @change="onChangeSingleStatusDropdown(material)">

                                <option v-for="(status, index) in sponsorshipStatuses" :key="index + 'sponsorshipStatuses'"
                                        :value="status.sponsorship_status_id">
                                    {{ status.sponsorship_status_name }}
                                </option>

                            </AppInputSelectDropdown>

                        </div>

                        <div
                            class="singleMaterialRow__data special-labels md-layout-item md-xlarge-size-10 md-large-size-10 md-medium-size-10 mobile-remove">
                            {{ material.size }}
                        </div>
                        <div
                          class="singleMaterialRow__data special-labels md-layout-item md-xlarge-size-10 md-large-size-10 md-medium-size-10">
                            {{ material.quantity }}
                        </div>

                    </div>
                </div>
            </div>
        </transition>

        <md-snackbar
            md-position="center"
            :md-active.sync="msgSnackbar.show"
            :md-duration="msgSnackbar.duration"
            md-persistent>

            <span>{{ msgSnackbar.message }}</span>
            <md-button class="md-primary" @click="msgSnackbar.show = false">Got it</md-button>

        </md-snackbar>

        <md-snackbar
            md-position="center"
            :md-active.sync="snackbar.show"
            :md-duration="snackbar.duration"
            md-persistent>

            <span>{{ snackbar.message }}</span>
            <md-button class="md-primary" @click="undoStatusUpdate">Undo</md-button>

        </md-snackbar>
    </div>
</template>

<script>

import AppInputSelectDropdown from '@/components/Base/AppInputSelectDropdown';
import {InitializeOrderMixin} from '@/mixins/InitializeOrderMixin';
import MaterialPDF from "@/components/MaterialDashboard/MaterialPDF.vue";

export default {
    name: 'MaterialDashboardTable',
    mixins: [InitializeOrderMixin],
    props: {
        materialsProp: {
            type: Array,
        },
        searchStr: {
            type: String,
        },
        orderTypeProp: {
            type: String,
            default: 'sponsorship',
        },
    },

    data: function() {
        return {
            fallbackImage: document.location.origin + require('@/assets/img/empty_state_image.png'),
            currentMaterialID: '',
            currentStatusID: '',
            isAllChecked: false,
            loadingSpinner: true,
            snackbar: {
                show: false,
                message: 'Last chance to undo the changes?',
                duration: 4000,
            },
            msgSnackbar: {
                show: false,
                message: '',
                duration: 4000,
            },
            undo: false,
            materialsForDropdown: [],
            sponsorshipStatuses: [],
            signageOrderStatuses: [],
            materials: [],
            orderType: '',
            pdfDialog: false,
            pdf: "",
        };
    },
    components: {
        AppInputSelectDropdown,
        MaterialPDF
    },

    methods: {

        changeStatuses() {

            if (this.currentStatusID.length > '0') {
                for (let i = 0; i < this.materials.length; i++) {
                    if (this.materials[i].is_checked) {
                        this.materials[i].status = this.currentStatusID;
                        this.onChangeSingleStatusDropdown(this.materials[i]);
                    }
                }
            } else {
                this.showSnackbarMsg('You must change the main material so you can send to queue!');
            }
        },

        onChangeSingleStatusDropdown(material) {

            let payload = {};

            if (material.mediaType === 'signage') {

                payload = {
                    status_id: material.status,
                };

            } else {

                payload = {
                    sponsorship_status: material.status,
                };

            }

            material.updateOrder(payload).then(() => {
                this.$emit('getAllOrders');

                this.$emit('searchInput', this.searchStr);
                this.showSnackbarMsg('Status was changed !');

            }).catch(err => {

                console.log(err);
                this.showSnackbarMsg(err.message);
            });
        },
        updateOrders(orders, orderType = 'sponsorship'){

            this.orderType = orderType;
            this.materials = orders;
            this.$forceUpdate();
        },

        printThisOrder(material) {

            let mediaType = '';

            if (material.mediaType) {
                mediaType = 'sponsorships';
            } else {
                mediaType = 'signage-orders';
            }

            this.$axios.get(`/${mediaType}/${material.id}/print-ticket`).then(res => {
                // this.pdfDialog = true;
                // this.pdf = res.data;
                window.open(res.data, '_blank');

//                material.statusName = 'Queued for production';
            }).catch(err => {
                this.$emit('printFailed', err.message);
            });

        },
        alphabeticalOrder(fieldToReorder) {
            this.$emit('reOrder', fieldToReorder);
        },
        showSnackbarMsg(message) {
            this.msgSnackbar.message = message;
            this.msgSnackbar.show = true;
        },

        sendToQueueMaterialsDropdown() {

            if (this.currentMaterialID.length > '0') {
                for (let i = 0; i < this.materials.length; i++) {
                    if (this.materials[i].is_checked) {
                        this.materials[i].materialId = this.currentMaterialID;
                        this.onChangeSingleMaterialDropdown(this.materials[i]);
                    }
                }
            } else {
                this.showSnackbarMsg('You must change the main material so you can send to queue!');
            }
        },

        onChangeSingleMaterialDropdown(material) {

            material.setUpOrderMaterial().then(() => {
//                this.$emit('getAllOrders');

                this.$emit('searchInput', this.searchStr);
                this.showSnackbarMsg('Material was changed !');

            }).catch(err => {

                console.log(err);
                this.showSnackbarMsg(err.message);
            });
        },

        getMaterials() {
            this.materialsForDropdown = this.$store.getters['materials/getMaterials'];
        },

        getSignageOrderStatuses(){

            return this.$axios.get('signage-statuses').then(res => {

                this.signageOrderStatuses = res.data;
            });
        },

        getSponsorshipStatuses(){

            return this.$axios.get('sponsorship-statuses').then(res => {

                console.log('set sponsorshipStatuses')
                this.sponsorshipStatuses = res.data;
            });
        },

        async getStatuses() {

            await this.getSignageOrderStatuses();
            await this.getSponsorshipStatuses();
        },

        showSnackBar() {
            this.undo = false;
            this.snackbar.show = true;
        },
        undoStatusUpdate() {
            this.undo = true;
            this.snackbar.show = false;
        },
        updateMarkedAsInstalled() {

            this.showSnackBar();

            setTimeout(() => {

                if (this.undo) return;

                for (let i = 0; i < this.materials.length; i++) {

                    if (this.materials[i].is_checked) {

                        this.materials[i].markInstalled();
                    }
                }

            }, 4000);
        },

        updateMarkedAsRemoved() {

            this.showSnackBar();

            setTimeout(() => {

                if (this.undo) return;

                for (let i = 0; i < this.materials.length; i++) {

                    if (this.materials[i].is_checked) {

                        this.materials[i].markRemoved();
                    }
                }

            }, 4000);
        },

        async loadData() {

            // try {
            this.getMaterials();
            await this.getStatuses();
            // } catch (err) {
            //
            //     this.showSnackbar(err.message);
            // }
        },

        getAllChecked() {

            let sponsorshipId = [];
            let signageId = [];

            let sponsorshipMaterials = [];
            let signageMaterials = [];

            for (let i = 0; i < this.materials.length; i++) {
                if (this.materials[i].is_checked) {

                    if (this.materials[i].mediaType === 'sponsorship') {
                        sponsorshipMaterials.push(this.materials[i]);
                        sponsorshipId.push(this.materials[i].id);
                    } else {
                        signageMaterials.push(this.materials[i]);
                        signageId.push(this.materials[i].id);
                    }
                }
            }

            if (signageId.length > 0) {



                let signageIdsObject = {
                    'signage_orders': signageId,
                };

                this.$axios.post(`/signage-order-print-tickets`, signageIdsObject).then(res => {
                    window.open(res.data, '_blank');

                    for (let i = 0; i < signageMaterials.length; i++) {
//                        signageMaterials[i].statusName = 'Queued for production';
                    }

                }).catch(err => {
                    this.$emit('printFailed', err.message);
                });
            }

            if (sponsorshipId.length > 0) {

                let sponsorshipIdsObject = {
                    sponsorships: sponsorshipId,
                };

                this.$axios.post(`/sponsorship-print-tickets`, sponsorshipIdsObject).then(res => {
                    window.open(res.data, '_blank');


                    for (let i = 0; i < sponsorshipMaterials.length; i++) {
//                        sponsorshipMaterials[i].statusName = 'Queued for production';
                    }

                }).catch(err => {
                    this.$emit('printFailed', err.message);
                });
            }
        },
    },

    // mounted() {
    //   let recaptchaScript = document.createElement("script");
    //   recaptchaScript.setAttribute(
    //       "src",
    //       "//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.min.js"
    //   );
    //   // recaptchaScript.setAttribute("src", "//mozilla.github.io/pdf.js/build/pdf.js");
    //   document.head.appendChild(recaptchaScript);
    //
    // },

  created() {

        let self = this;

        self.orderType = self.orderTypeProp;

        self.loadData().then(() => {

            console.log('set materials')
            self.materials = self.materialsProp;
            self.loadingSpinner = false;
        });
    },

    watch: {
        isAllChecked(value) {

            for (let i = 0; i < this.materials.length; i++) {

                this.materials[i].is_checked = value;
            }

            this.$forceUpdate();
        },
    },
};
</script>

<style lang="scss" scoped>

//.container-mobile-installation-modal {
//    position: absolute;
//    top: 30%;
//    text-align: center;
//    left: 0;
//    outline: 9999px solid rgba(0, 0, 0, 0.5);
//    background-color: #ffffff;
//    /* margin-right: 20px; */
//}

.singleMaterialRow__header {
    text-align: center;
    background-color: white;
    font-weight: bold;
    color: #222222;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid gray;

    @include respond(small) {
        font-size: 0.8rem;
    }
}

.singleMaterialRow {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 15px;
    border-bottom: 2px solid #7D7D7D;

    @include respond(small) {
        cursor: pointer;
    }
}

.blue-color {
    color: #313B8E;
    font-weight: 500;
}

.singleMaterialRow__data {
    font-size: 1.4rem;
}

.singleMaterialRow:hover {
    cursor: pointer;
}

.sticky_header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 9;
}

.material-table {
    border: 1px solid #B90B0B;
    height: 98vh;
    overflow: scroll;

    .send-to-queue-btn {
        width: 100%;

        @include respond(small) {
            margin-top: 2vh;
        }
    }
}

.check-btn {
    padding-left: 4rem;
    padding-top: 0.8rem;

    @include respond(small) {
        padding-left: 1.3rem;
    }

    .input-check-box {
        transform: scale(2.1);

        @include respond(small) {
            transform: scale(1.6);
        }
    }
}

.mobile-remove {

    @include respond(small) {
        display: none;
    }
}

.singleMaterialRow__btns {
    border-top: 1px solid gray;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 2px solid gray;

    .md-icon {
        //padding-left: 20px;
        //font-size: 45px !important;
        //
        //@include respond(small) {
        //    font-size: 25px !important;
        //    padding-left: 10px;
        //}
    }
}

.main-icons {
    margin-left: 35px;

    @include respond(small) {
        margin-left: 15px;
    }
}

.expand-btn {
    cursor: pointer;
}

.expanded {
    background-color: #f1f1f1;
}

.icons {
    cursor: pointer;
    margin-left: 45px;
    font-size: 45px !important;

    @include respond(small) {
        font-size: 45px !important;
        margin-left: 45px;
    }
}

.material-table {
    margin-top: 40px;
}

.status_name {
    @include respond(small) {
        text-align: center;
    }
}

.close-modal-button {
    margin-bottom: 20px;
}

.materials-select__field {
    height: 30px;
    border-radius: 20px;
    border: 2px solid;
}

.singleMaterialRow__btns {

    .md-layout-item {
        padding-left: 40px;
    }
}

.material-hdrs {
    cursor: pointer;
}
</style>